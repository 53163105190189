import React from "react";
import "./CustomNode.css";

const CustomNode = ({ data }) => {
  return (
    <div className="custom-node">
      <div className="custom-node-header">{data.label}</div>
      <div className="custom-node-content">
        <p>Contenuto personalizzato</p>
      </div>
    </div>
  );
};

export default CustomNode;
