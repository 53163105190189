import React, { useEffect, useState } from "react";
import "./HomePage.css";
import Notification from "../../components/UI/Notification/Notification";
import { FaRegClock } from "react-icons/fa";
import Modello3d from "../../components/Modello3d/Modello3d";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import ServiceCarousel from "../../components/ServiceCarousel";
import TimeLine from "../../components/TimeLine/TimeLine";
import Animation from "../../components/VideoAnimazione/Animation";
import TitleOptions2 from "../../components/TitleOptions2/TitleOptions2";
const HomePage = () => {
  const [showIntro, setShowIntro] = useState(true);
  const [isIntroScrolled, setIsIntroScrolled] = useState(false);
  const items = [
    {
      title: "Sviluppiamo Digital Twin",
      description:
        "Creiamo i modelli digitali dei tuoi impianti, con l'obbiettivo di simulare, monitorare e ottimizzare i processi industriali. Per noi il Digital Twin non è un concetto di marketing, ma uno strumento innovativo per fornire un vantaggio competitivo alla vostra azienda",
      icon: "🏭",
    },
    {
      title: "Hardware raccolta dati dal PLC",
      description:
        "Forniamo i dispositivi hardware per interfacciarci con i Vostri PLC, raccogliendo i dati e analizzando i parametri di produzione. Con un'ampia gamma di possibilità, rendiamo questa fase facile ed accessibile, riducendo la complessità di installazione",
      icon: "🔌",
    },
    {
      title: "Manutenzione Predittiva sui Macchinari",
      description:
        "Implementiamo soluzioni per la manutenzione predittiva, riducendo i tempi di fermo e aumentando la durata dei Vostri macchinari. Sfruttando algoritmi di Machine Learning e Data Analysis, ti mostriamo lo stato dei macchinari in maniera chiara ed efficiente",
      icon: "🔧",
    },
    {
      title: "Digitalizzazione del Plant Aziendale",
      description:
        "Trasformiamo gli impianti fisici in ambienti digitali per la raccolta e l'analisi dei dati, per fornivi una visione innovativa e più approfondita sulle vostre linee",
      icon: "💻",
    },
    {
      title: "Simulation Environment",
      description:
        "Creiamo ambienti di simulazione parametrica per ottimizzare i Vostri processi industriali e ridurre gli errori. La rappresentazione fedele del macchinario non è più un nice to have, ma diventa una vera e copia su cui agire attivamente",
      icon: "⚙️",
    },
    {
      title: "Virtual Control",
      description:
        "Creiamo soluzioni sicure per il controllo remoto della produzione, per interventi a distanza su componenti cost-saving non critici.",
      icon: "🖥️",
    },
  ];

  const services = [
    "Digital Twin",
    "Real Time Monitoring per Linee di Produzione",
    "Virtual Commissioning",
    "Manutenzione Predittiva per Impianti Industriali",
    "Simulation Environment per Processi Produttivi",
  ];

  const data = [
    {
      id: 1, // Unique ID for each item
      title: "Cerchi un partner per la digitalizzazione dei tuoi processi?",
      description:
        "Offriamo soluzioni integrate per la digitalizzazione completa del tuo impianto. Attraverso la connessione diretta ai sistemi di controllo, ti permettiamo di monitorare, analizzare e ottimizzare in tempo reale ogni fase della produzione. Con il nostro supporto, il tuo impianto diventa un ecosistema digitale perfettamente sincronizzato.",
      buttonText: "Scopri come",
      buttonLink: "/digitalizzazione",
      photo: "logo.png", // Modello GLB per l'assembly line
    },
    {
      id: 2, // Unique ID for each item
      title: "Vuoi una soluzione custom per monitorare i tuoi processi?",
      description:
        "Progettiamo soluzioni personalizzate per il monitoraggio avanzato dei tuoi processi industriali. Grazie all'integrazione di sensori e dispositivi hardware, raccogliamo dati in tempo reale dai tuoi impianti e forniamo dashboard intuitive per un controllo completo e un'analisi predittiva accurata.",
      buttonText: "Scopri di più",
      buttonLink: "/monitoraggio",
      photo: "logo.png", // Lo stesso modello GLB
    },
    {
      id: 3, // Unique ID for each item
      title:
        "Vuoi fare delle simulazioni parametriche per testare la produzione dei tuoi impianti?",
      description:
        "Mettiamo a tua disposizione ambienti di simulazione avanzata per testare parametri produttivi e ottimizzare i processi prima della loro implementazione. Le nostre soluzioni ti permettono di anticipare problemi e inefficienze, migliorando la produttività e riducendo il rischio di errori costosi.",
      buttonText: "Approfondisci",
      buttonLink: "/simulazioni",
      photo: "logo.png", // Lo stesso modello GLB
    },
    {
      id: 4, // Unique ID for each item
      title:
        "Vuoi gestire la tua manutenzione? Pianifica la manutenzione in anticipo per evitare fermi macchina.",
      description:
        "Le nostre soluzioni di manutenzione predittiva ti aiutano a programmare interventi mirati, riducendo i tempi di fermo e aumentando l'affidabilità dei tuoi impianti. Grazie ai dati raccolti in tempo reale, possiamo prevedere e prevenire guasti, mantenendo i tuoi macchinari sempre operativi.",
      buttonText: "Scopri come",
      buttonLink: "/manutenzione",
      photo: "logo.png", // Lo stesso modello GLB
    },
    {
      id: 5, // Unique ID for each item
      title:
        "Virtual commissioning se sei una OEM: testare il PLC prima della realizzazione della macchina.",
      description:
        "Offriamo soluzioni di virtual commissioning per OEM, consentendoti di testare il PLC e i sistemi di controllo prima della realizzazione fisica della macchina. Questo approccio riduce gli errori grossolani e accelera il processo di messa in funzione, garantendo una transizione più fluida e senza imprevisti.",
      buttonText: "Scopri di più",
      buttonLink: "/commissioning",
      photo: "logo.png", // Lo stesso modello GLB
    },
  ];

  const [currentService, setCurrentService] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentService((prevService) => (prevService + 1) % services.length);
    }, 900); // Cambia il servizio ogni 3 secondi

    return () => clearInterval(interval); // Pulisce l'intervallo quando il componente viene smontato
  }, [services.length]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsIntroScrolled(true);
      setTimeout(() => {
        setShowIntro(false);
      }, 500);
    }, 4000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <>
      {!showIntro && <Navbar />}
      {showIntro && (
        <section className="intro-screen">
          <h1 className="intro-text">
            Gemini automation |{" "}
            <ServiceCarousel
              currentService={currentService}
              services={services}
            />
          </h1>
        </section>
      )}
      <section className={`home-page ${showIntro ? "" : "show"}`}>
        <div className="home-content">
          <Notification
            text="Siamo in cerca di clienti"
            icon={<FaRegClock />}
            onClick={(status) => console.log("Status attivo:", status)}
          />
          <h1>Digitalizziamo i processi industriali</h1>
          <h6>
            Siamo il vostro Innovation Partner, iniziate la digitalizzazione
            sfruttando ingegneria avanzata
          </h6>
        </div>
        <div>
          <Animation
            videoSrc="/AnimationPLC.mp4"
            videoAlt="Video dimostrativo"
            autoplay={true}
            loop={true}
            muted={true}
            controls={false}
            theme="dark"
          />
        </div>
        {/* <AnimationGemini /> */}
        <h1>
          Siamo un gruppo di ingegneri, con competenze in automazione,
          elettronica e sviluppo sfotware
        </h1>
      </section>
      <section className={`services ${showIntro ? "" : "show"}`}>
        <TitleOptions2
          title="Ecco cosa facciamo."
          sub_title=""
          items={items}
          backgroundColor="var(--color-background)"
        />
      </section>
      <div className={`home-page ${showIntro ? "" : "show"}`}>
        <h1
          style={{
            textAlign: "center",
            marginBottom: "10vh",
            marginTop: "10vh",
          }}
        >
          Un concetto complesso, reso semplice.
        </h1>
        <TimeLine data={data} />
      </div>
      {/* {!showIntro && (
        <section className="gemello-digitale">
          <Modello3d modelPath="/assembly-line.glb" />
        </section>
      )} */}
      {!showIntro && <Footer />}
    </>
  );
};

export default HomePage;
