import React from "react";
import { FaInstagram, FaLinkedin, FaFacebook } from "react-icons/fa"; // Importa le icone
import "./Footer.css";
import Button from "../UI/Button/Button"; // Importa il componente Button

const Footer = () => {
  return (
    <>
      <div className="footer-container">
        {/* Prima riga */}
        <div className="footer-top">
          <div className="footer-intro">
            <h2>Osa cambiare. Digitalizza la tua azienda</h2>
            <p>Entra in contatto, fissa una call o scrivici una mail.</p>
            <Button text="Contatti" className="btn-primary" />
          </div>
        </div>

        {/* Seconda riga */}
        <div className="footer-links">
          <div>
            <h3>Chi siamo</h3>
            <a href="chi-siamo">About </a>
            <a href="lavora-con-noi">Carriere</a>
          </div>
          <div>
            <h3>Casi Studio</h3>
            <a href="/casi-studio">Visualizza Casi Studio</a>
          </div>
          <div>
            <h3>Servizi</h3>
            <a href="/real-time-monitoring">Real Time Monitoring</a>
            <a href="/virtual-commissioning">Virtual Commissioning</a>
            <a href="/ambiente-simulazione">Ambiente di Simulazione</a>
            <a href="/manutenzione-predittiva">Manutenzione Predittiva</a>
          </div>
        </div>
      </div>

      {/* Componente sottostante */}
      <div className="footer-bottom">
        <div className="footer-logo">
          <img src="/logo.png" alt="Logo" />
        </div>
        <div className="footer-copyright">
          <p>&copy; 2024 Tutti i diritti riservati. Gemini Automation.</p>
        </div>
        <div className="footer-social">
          <a href="#">
            <FaInstagram size={24} />
          </a>
          <a href="#">
            <FaLinkedin size={24} />
          </a>
          <a href="#">
            <FaFacebook size={24} />
          </a>
        </div>
      </div>
    </>
  );
};

export default Footer;
