import React from "react";
import "./TimeLine.css";

const TimeLine = ({ data }) => {
  return (
    <div className="timeline-container">
      <div className="timeline">
        {data.map((item, index) => (
          <div key={index} className="timeline-item">
            <div className="timeline-line">
              <div className="timeline-dot"></div> {/* Punto sulla timeline */}
            </div>
            <div className="timeline-content">
              <div className="timeline-box">
                <div className="timeline-text">
                  <h3>{item.title}</h3>
                  <p>{item.description}</p>
                  <button className="timeline-button">
                    <a href={item.buttonLink}>{item.buttonText}</a>
                  </button>
                </div>

                <div className="timeline-image">
                  <img
                    src={item.photo}
                    alt={item.title}
                    className="timeline-img"
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TimeLine;
