import { useGLTF } from "@react-three/drei";
import React, { useRef } from "react";
import { useFrame } from "@react-three/fiber"; // Importa useFrame per animare il modello
// Importa i materiali da un file separato
import {
  grayMaterialflangia,
  blackMaterial,
  yellowMaterial,
  greyMaterial,
} from "../colors/colors";

export default function Fanuc_simplified1_withEE_motor(props) {
  const { nodes } = useGLTF("/3D/fanuc_simplified1_withEE_motor.gltf");

  // Riferimento al gruppo principale del modello per applicare la rotazione
  const fanucRef = useRef();

  // Utilizza useFrame per far ruotare il modello automaticamente
  useFrame(() => {
    if (fanucRef.current) {
      fanucRef.current.rotation.y += 0.005; // Rotazione continua
    }
  });

  return (
    <group ref={fanucRef} {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Banchetti.geometry}
        material={greyMaterial}
        position={[0.01, 0, -0.064]} // Puoi regolare la posizione per adattarla
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Banchetto_Fanuc.geometry}
        material={greyMaterial}
        position={[0.365, -0.186, -0.064]} // Regola la posizione per visibilità
      />
      <group position={[0.4, 0.1, -0.07]} rotation={[-1.571, 1.571, 0]}>
        <skinnedMesh
          geometry={nodes["end-effector"].geometry}
          material={nodes["end-effector"].material}
          skeleton={nodes["end-effector"].skeleton}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.fanuc001.geometry}
          material={greyMaterial}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
          position={[0.1, -0.07, -0.5]} // Posizione aggiustata
        />
        <skinnedMesh
          geometry={nodes.fanuc002.geometry}
          material={yellowMaterial}
          skeleton={nodes.fanuc002.skeleton}
        />
        <skinnedMesh
          geometry={nodes.fanuc003.geometry}
          material={yellowMaterial}
          skeleton={nodes.fanuc003.skeleton}
        />
        <skinnedMesh
          geometry={nodes.fanuc004.geometry}
          material={yellowMaterial}
          skeleton={nodes.fanuc004.skeleton}
        />
        <skinnedMesh
          geometry={nodes.fanuc005.geometry}
          material={greyMaterial}
          skeleton={nodes.fanuc005.skeleton}
        />
        <skinnedMesh
          geometry={nodes.fanuc006.geometry}
          material={blackMaterial}
          skeleton={nodes.fanuc006.skeleton}
        />
        <skinnedMesh
          geometry={nodes.fanuc007.geometry}
          material={greyMaterial}
          skeleton={nodes.fanuc007.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Flangiainf.geometry}
          material={grayMaterialflangia}
          skeleton={nodes.Flangiainf.skeleton}
        />
        <skinnedMesh
          geometry={nodes.pinza1_1.geometry}
          material={blackMaterial}
          skeleton={nodes.pinza1_1.skeleton}
        />
        <skinnedMesh
          geometry={nodes.pinza2_1.geometry}
          material={blackMaterial}
          skeleton={nodes.pinza2_1.skeleton}
        />
        <skinnedMesh
          geometry={nodes.pinza3_1.geometry}
          material={blackMaterial}
          skeleton={nodes.pinza3_1.skeleton}
        />
        <primitive object={nodes.root} />
        <primitive object={nodes.neutral_bone} />
        <primitive object={nodes.neutral_bone_1} />
      </group>
    </group>
  );
}

// Pre-carica il modello per ottimizzare il caricamento
useGLTF.preload("/3D/fanuc_simplified1_withEE_motor.gltf");
