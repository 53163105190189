import React from "react";
import "./Notification.css"; // Importa gli stili per il componente

const Notification = ({ text, icon, onClick }) => {
  return (
    <div className="status-indicator">
      {/* Punto verde sempre attivo */}
      <div className="dot active" />
      <div className="text">{text}</div>
      <div className="icon">{icon}</div>
    </div>
  );
};

export default Notification;
