import React, { useEffect, useState } from "react";

const ServiceCarousel = ({ currentService, services }) => {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    // Rimuove l'animazione e la riapplica ogni volta che il servizio cambia
    setAnimate(true);
    const timer = setTimeout(() => {
      setAnimate(false); // Ferma l'animazione
    }, 800); // Durata dell'animazione in millisecondi
    return () => clearTimeout(timer); // Cleanup dell'effetto
  }, [currentService]); // L'effetto si attiva ogni volta che cambia il servizio

  return (
    <span className={`service ${animate ? "animate" : ""}`}>
      {services[currentService]}
    </span>
  );
};

export default ServiceCarousel;
