import React from "react";
import "./DescriptionPhotolong.css";

const DescriptionPhotolong = ({
  title,
  description, // Aggiunta della descrizione
  imageSrc,
  imageAlt = "Image",
  theme = "light", // Default: modalità chiara
}) => {
  return (
    <div
      className={`description-photolong-container ${
        theme === "dark" ? "dark-mode" : "light-mode"
      }`}
    >
      <div className="description-photolong">
        {/* Titolo a sinistra (25%) */}
        <div className="title-section">
          <h1>{title}</h1>
        </div>

        {/* Descrizione a destra (75%) */}
        <div className="description-section">
          <h6>{description}</h6>
        </div>
      </div>

      {/* Immagine sotto, larga 100% */}
      <div className="image-section">
        <img src={imageSrc} alt={imageAlt} />
      </div>
    </div>
  );
};

export default DescriptionPhotolong;
