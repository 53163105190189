import React from "react";
import "./CasiStudioPage.css";
import Title from "../../components/Title/Title";

const CasiStudioPage = () => {
  const cases = [
    {
      id: 1,
      imgSrc: "/production-line.png",
      title: "Gemello Digitale di una piccola linea di produzione di motori",
      description:
        "Gemello Digitale 3D di una piccola linea di produzione di motori con monitoraggio in tempo reale.",
    },
    {
      id: 5,
      imgSrc: "warehouse.png",
      title: "Gemello Digitale per un magazzino",
      description:
        "Rappresentazione in tempo reale della situazione di un magazzino multi-fila con trasporto oggetti tramite AGV (Automated Guided Vehicles).",
    },
    {
      id: 4,
      imgSrc: "rtm.png",
      title: "Monitoraggio in tempo reale di una linea di produzione",
      description:
        "Monitoraggio in tempo reale dei dati di una linea di produzione attraverso il nostro hardware Gemini connesso al PLC. Dashboard semplice per controllare allarmi e funzionamento da remoto.",
    },
    {
      id: 2,
      imgSrc: "robot-arm.png",
      title: "Manutenzione Predittiva di un braccio robotico",
      description:
        "Studio delle vibrazioni e degli assorbimenti di corrente di ogni giunto di un braccio robotico. Applicazione di Machine Learning per prevedere guasti e rilevare anomalie senza dati storici.",
    },
    {
      id: 3,
      imgSrc: "saix.png",
      title: "Gemello Digitale per una flotta di camion",
      description:
        "Monitoraggio dell'impianto frenante e sterzante, con rappresentazione dello stato in 3D.",
    },
  ];

  return (
    <div className="cs-page">
      <div className="cs">
        <Title title="I nostri casi studio." />
        <div className="cs-content">
          {cases.map((caseItem) => (
            <div
              className="cs-card"
              key={caseItem.id}
              style={{ backgroundImage: `url(${caseItem.imgSrc})` }}
            >
              <div className="cs-card-content">
                <h3>{caseItem.title}</h3>
                <p>{caseItem.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CasiStudioPage;
