import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom"; // Importa Navigate
import Navbar from "./components/Navbar/Navbar";
import HomePage from "./pages/HomePage/HomePage";
import AboutPage from "./pages/AboutPage/AboutPage.jsx";
import RealTimeMonitoring from "./pages/RealTimeMonitoring/RealTimeMonitoring";
import Servizi from "./pages/ServicePage/ServicePage";
import ContactPage from "./pages/ContactPage/ContactPage";
import Footer from "./components/Footer/Footer";
import Lavoraconnoi from "./pages/Lavoraconnoi/Lavoraconnoi.jsx";
import CasiStudio from "./pages/CasiStudioPage/CasiStudioPage";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route
          path="/chi-siamo"
          element={
            <>
              <Navbar />
              <AboutPage />
              <Footer />
            </>
          }
        />
        <Route
          path="/lavora-con-noi"
          element={
            <>
              <Navbar />
              <Lavoraconnoi />
              <Footer />
            </>
          }
        />
        <Route
          path="/contatti"
          element={
            <>
              <Navbar />
              <ContactPage />
              <Footer />
            </>
          }
        />
        <Route
          path="/real-time-monitoring"
          element={
            <>
              <Navbar />
              <RealTimeMonitoring />
              <Footer />
            </>
          }
        />
        <Route
          path="/virtual-commissioning"
          element={
            <>
              <Navbar />
              <Servizi />
              <Footer />
            </>
          }
        />

        <Route path="*" element={<Navigate to="/" />} />{" "}
        <Route
          path="/casi-studio"
          element={
            <>
              <Navbar />
              <CasiStudio />
              <Footer />
            </>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
