import React from "react";
import TitlePhotos from "../../components/TitlePhotos/TitlePhotos";
import {
  FaProjectDiagram,
  FaCode,
  FaCube,
  FaShoppingCart,
  FaMicrochip,
} from "react-icons/fa";

import TitleOptions from "../../components/TitleOptions/TitleOptions";

const Lavoraconnoi = () => {
  const photos = [
    "https://via.placeholder.com/300x200", // Foto 1
    "https://via.placeholder.com/400x200", // Foto 2
    "https://via.placeholder.com/400x300", // Foto 3
    "https://via.placeholder.com/300x300", // Foto 4
  ];

  const leftItems = [
    {
      title: "Junior +1 Years",
      subtitle: "Frontend React Developer",
      description: "Full time",
    },
    {
      title: "Senior +3 Years",
      subtitle: "Technical Sales",
      description: "Freelance",
    },
    {
      title: "Senior +3 Years",
      subtitle: "ML&AI Engineer",
      description: "Freelance/Full time",
    },
  ];

  const rightList = [
    { text: "Digital Twin", icon: <FaProjectDiagram /> }, // Icona per progetti e diagrammi
    { text: "Software Architecture", icon: <FaCode /> }, // Icona legata al codice e alla programmazione
    { text: "3D Design", icon: <FaCube /> }, // Icona di un cubo che rappresenta il design 3D
    { text: "Technical Sales", icon: <FaShoppingCart /> }, // Icona di un carrello per le vendite tecniche
    { text: "Electronics", icon: <FaMicrochip /> }, // Icona di un microchip per rappresentare l'elettronica
  ];

  return (
    <div className="about-page">
      <div className="about-content">
        <div>
          <TitlePhotos title="Lavora con noi." photos={photos} />
        </div>
        <div>
          <TitleOptions
            title="Posizioni aperte"
            leftItems={leftItems}
            rightTitle="Hot Topics"
            rightList={rightList}
            backgroundColor="var(--color-background-dirty-dark)"
            sub_title="Per una candidatura spontanea inviaci il tuo CV"
          />
        </div>
      </div>
    </div>
  );
};

export default Lavoraconnoi;
