import React from "react";
import "./TitlePhotos.css";

const TitlePhotos = ({ title, photos }) => {
  if (!photos || photos.length < 4) {
    console.error("Il componente richiede almeno 4 foto.");
    return null;
  }

  return (
    <div className="title-photos-container">
      <h1 className="title">{title}</h1>
      <div className="photos-grid">
        <div className="row">
          <div className="photo photo-rect-left">
            <img src={photos[0]} alt="" />
          </div>
          <div className="photo photo-rect-right">
            <img src={photos[1]} alt="" />
          </div>
        </div>
        <div className="row">
          <div className="photo photo-rect-right">
            <img src={photos[2]} alt="" />
          </div>
          <div className="photo photo-rect-left">
            <img src={photos[3]} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TitlePhotos;
