import React from "react";

export default function Lighting() {
  return (
    <>
      {/* Luce ambientale debole per illuminare l'intero spazio */}
      <ambientLight intensity={0.4} color="#b8b8b8" />

      {/* Luce direzionale per simulare faretti direzionati */}
      <directionalLight
        position={[50, 100, 50]}
        intensity={1.0}
        castShadow
        color="#ffffff"
        shadow-mapSize-width={1024}
        shadow-mapSize-height={1024}
        shadow-camera-near={0.1}
        shadow-camera-far={500}
        shadow-camera-left={-50}
        shadow-camera-right={50}
        shadow-camera-top={50}
        shadow-camera-bottom={-50}
      />

      {/* Luce emisferica per un'illuminazione diffusa e bilanciata */}
      <hemisphereLight
        skyColor={0xaaaaaa} // Colore della luce superiore
        groundColor={0x333333} // Colore della luce inferiore per simulare il pavimento
        intensity={0.3}
      />

      {/* Luce puntiforme per effetti focalizzati */}
      <pointLight
        position={[0, 5, 0]}
        intensity={0.2} // Abbassata l'intensità per evitare riflessi evidenti
        decay={2}
        castShadow
        color="#ffcc66"
        distance={30}
      />

      {/* Luce puntiforme morbida per simulare una luce diffusa */}
      <pointLight
        position={[-10, 10, -10]}
        intensity={0.2} // Abbassata per evitare riflessi evidenti
        decay={1.5}
        castShadow
        color="#bbbbbb"
        distance={50}
      />
    </>
  );
}
