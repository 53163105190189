// src/pages/AboutPage.jsx
import React from "react";
import "./AboutPage.css"; // Import styles for the About page
import DescriptionPhoto from "../../components/DescriptionPhoto/DescriptionPhoto";
import DescriptionPhotolong from "../../components/DescriptionPhotolong/DescriptionPhotolong";

const AboutPage = () => {
  return (
    <div className="about-page">
      <div className="about-content">
        {/* Modalità scura con immagine a sinistra */}
        <DescriptionPhoto
          title="Chi siamo."
          subtitle="Gruppo di amici appassionati di tecnologia e digitalizzazione, oggi sviluppiamo Digital Twin."
          imageSrc="https://via.placeholder.com/500"
          imageAlt="Seconda immagine"
          imageLeft={true}
          theme="dark"
        />

        <DescriptionPhotolong
          title="Filosofia Aziendale"
          description="Siamo due giovani imprenditori appassionati di innovazione e trasformazione digitale. La nostra missione è digitalizzare i processi industriali, aiutando le aziende a raggiungere maggiore efficienza e competitività. Crediamo nel potere di connettere ambienti virtuali a servizi innovativi, adattandoci alle nuove tecnologie per un futuro in continua evoluzione. Vogliamo diventare un punto di riferimento per soluzioni scalabili, ottimizzando ogni processo aziendale per una crescita sostenibile e senza limiti."
          imageSrc="https://via.placeholder.com/1200x600"
          imageAlt="Esempio di immagine"
          theme="dark"
        />
      </div>
    </div>
  );
};

export default AboutPage;
