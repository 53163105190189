import React from "react";
import "./TitleOptions2.css";

const TitleOptions2 = ({
  title,
  items, // Array di oggetti {title, subtitle, description, icon}
  backgroundColor,
  sub_title,
}) => {
  return (
    <div
      className="title-options2-container"
      style={{
        backgroundColor: backgroundColor || "var(--color-background-light)",
      }}
    >
      {/* Titolo principale */}
      <h1 className="title-options2-title">{title}</h1>
      {sub_title && <h2 className="title-options2-subtitle">{sub_title}</h2>}

      {/* Griglia di 6 elementi */}
      <div className="grid-container">
        {items &&
          items.map((item, index) => (
            <div key={index} className="grid-item">
              {" "}
              {/* Add key prop here */}
              <div className="grid-item-title-container">
                <h3 className="grid-item-title">{item.title}</h3>
                <div className="grid-item-icon">{item.icon}</div>
              </div>
              <p className="grid-item-description">{item.description}</p>
            </div>
          ))}
      </div>
    </div>
  );
};

export default TitleOptions2;
