import React, { useState, useCallback } from "react";
import BackgroundPhoto from "../../components/BackgroundPhoto/BackgroundPhoto";
import ReactFlow, { Background, Controls, applyNodeChanges, applyEdgeChanges, addEdge } from "reactflow";
import "reactflow/dist/style.css";

import CustomNode from "../../components/CustomNode/CustomNode";
import "./RealTimeMonitoring.css";

const RealTimeMonitoring = () => {
  const [nodes, setNodes] = useState([
    {
      id: "1",
      data: { label: "Nodo 1" },
      position: { x: 100, y: 100 },
      type: "custom",
    },
    {
      id: "2",
      data: { label: "Nodo 2" },
      position: { x: 300, y: 200 },
      type: "custom",
    },
  ]);

  const [edges, setEdges] = useState([
    {
      id: "e1-2",
      source: "1",
      target: "2",
      animated: true,
      style: { strokeWidth: 2 },
    },
  ]);

  const onNodesChange = useCallback((changes) => {
    setNodes((nds) => applyNodeChanges(changes, nds));
  }, []);

  const onEdgesChange = useCallback((changes) => {
    setEdges((eds) => applyEdgeChanges(changes, eds));
  }, []);

  return (
    <div className="rtm-page">
      <div className="rtm-content">
        <h1 className="rtm-title">Monitoraggio in tempo reale</h1>
        <h2 className="rtm-subtitle">Sai cosa succede dentro la tua azienda?</h2>
        <div className="rtm-info-container">
          <div className="rtm-info-box">
            <h3>Mappatura 2D del Plant</h3>
            <p>Ottieni aggiornamenti immediati sugli eventi cruciali della tua azienda.</p>
          </div>
          <div className="rtm-info-box">
            <ReactFlow
              nodes={nodes}
              edges={edges}
              nodeTypes={{ custom: CustomNode }}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              fitView
              style={{ width: "100%", height: "300px" }}
            >
              <Background color="black" variant="dots" />
              <Controls />
            </ReactFlow>
          </div>
          
        </div>
        <div className="rtm-info-container">

          <div className="rtm-info-box">
            <ReactFlow
              nodes={nodes}
              edges={edges}
              nodeTypes={{ custom: CustomNode }}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              fitView
              style={{ width: "100%", height: "300px" }}
            >
              <Background color="black" variant="dots" />
              <Controls />
            </ReactFlow>
          </div>
          <div className="rtm-info-box">
            <h3>Mappatura 2D del Plant</h3>
            <p>Ottieni aggiornamenti immediati sugli eventi cruciali della tua azienda.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RealTimeMonitoring;
