import * as THREE from "three";

export const grayMaterialflangia = new THREE.MeshStandardMaterial({
  color: 0x4b4b4b,
});

export const blackMaterial = new THREE.MeshStandardMaterial({
  color: 0x333333,
  metalness: 0.5,
  roughness: 0.7,
  opacity: 0.8,
});

export const yellowMaterial = new THREE.MeshStandardMaterial({
  color: 0xffc000,
  metalness: 0.2,
  roughness: 0.5,
  transparent: false,
});

export const greyMaterial = new THREE.MeshStandardMaterial({
  color: 0x808080,
  metalness: 0.3,
  roughness: 0.6,
  opacity: 0.9,
});

export const grayMaterial = new THREE.MeshStandardMaterial({
  color: 0x004e82,
  roughness: 0.8,
}); // Azzurro opaco

export const silverMaterial = new THREE.MeshStandardMaterial({
  color: 0x4b4b4b,
}); // Grigio scuro

export const darkGrayMaterial = new THREE.MeshStandardMaterial({
  color: 0x878787,
  roughness: 0.5,
}); // Grigio chiaro alluminio opaco
