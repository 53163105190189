import React from "react";
import "./DescriptionPhoto.css";

const DescriptionPhoto = ({
  title,
  subtitle,
  imageSrc,
  imageAlt = "Image",
  imageLeft = false, // Default: immagine a destra
  theme = "light", // Default: modalità chiara
}) => {
  return (
    <div
      className={`description-photo-container ${
        theme === "dark" ? "dark-mode" : "light-mode"
      }`}
    >
      <div
        className={`description-photo ${
          imageLeft ? "image-left" : "image-right"
        }`}
      >
        {/* Contenuto del testo */}
        <div className="text-content">
          <h1>{title}</h1>
          <h2>{subtitle}</h2>
        </div>

        {/* Contenuto dell'immagine */}
        <div className="image-content">
          <img src={imageSrc} alt={imageAlt} />
        </div>
      </div>

      {/* Linea separatrice sotto entrambi */}
      <div className="separator-line"></div>
    </div>
  );
};

export default DescriptionPhoto;
