// src/pages/ServicePage.jsx
import React from "react";
import { Canvas } from "@react-three/fiber";
import Lighting from "./Lighting";
import { Stage, OrbitControls } from "@react-three/drei"; // Import Text from drei
import "./ServicePage.css"; // Import styles for the Service page
import Fanuc from "../../GLTFJSX/Fanuc_1";
import { useNavigate } from "react-router-dom"; // For navigation

const ServicePage = () => {
  const navigate = useNavigate(); // Hook to navigate to ContactPage

  return (
    <div className="service-page">
      <div className="service-content">
        {/* <Notification
          text="Scopri i nostri servizi"
          icon={<FaCogs />}
          onClick={(status) => console.log("Notification status:", status)}
        /> */}
        <div className="text-and-image">
          <div className="service-text">
            <h1>
              Il futuro della tua azienda inzia <span>OGGI</span>
            </h1>
            <p>
              Dall'automazione industriale alla digitalizzazione, i nostri
              servizi sono progettati per ottimizzare i tuoi processi e
              migliorare la produttività. Scopri come possiamo aiutarti a
              raggiungere i tuoi obiettivi.
            </p>
          </div>
          <div className="service-image">
            <img src="/Mockup.png" alt="Mockup" />
          </div>
        </div>
      </div>

      <div className="full-width-message">
        <h2>
          Con noi il Digital Twin non è una questione di marketing. Sviluppiamo
          gemelli digitali nella loro completezza
        </h2>
      </div>

      <div className="model-and-info">
        <div className="model">
          <Canvas
            className="fullscreen-canvas"
            shadows
            camera={{ position: [0, 3, 5], fov: 60 }}
          >
            <color attach="background" args={["#161619"]} />
            <Lighting />
            <Fanuc />
            <Stage
              intensity={1.2}
              environment="warehouse"
              shadows={{ type: "soft" }}
              adjustCamera={0.8}
            />

            <OrbitControls
              makeDefault
              minPolarAngle={0}
              maxPolarAngle={Math.PI / 2}
              target={[0, 0, 0]} // Set the target to (0, 0, 0) to help orient the scene
              zoomSpeed={1.3} // Adjust the zoom speed
            />
          </Canvas>
        </div>
        <div className="info">
          <h3>
            Mappiamo il tuo impianto e lo digitalizziamo. Monitoriamo i
            macchinari in un ambietne virtuale
          </h3>
          <button onClick={() => navigate("/contact")}>Contattaci</button>
        </div>
      </div>
    </div>
  );
};

export default ServicePage;
