import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import "./Options3.css"; // Assicurati che il file CSS esista

const Options3 = ({ title, options }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          }
        });
      },
      { threshold: 0.1 } // Inizia l'animazione quando il 10% del componente è visibile
    );

    const elements = containerRef.current.querySelectorAll(".option");
    elements.forEach((element) => observer.observe(element));

    return () => observer.disconnect();
  }, []);

  return (
    <div className="options-container" ref={containerRef}>
      <h2>{title}</h2>
      <div className="options-grid">
        {options.map((option, index) => (
          <div className="option" key={index}>
            <h3>{option.title}</h3>
            {option.description && <p>{option.description}</p>}
            {option.buttonText && <button>{option.buttonText}</button>}
          </div>
        ))}
      </div>
    </div>
  );
};

Options3.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
      buttonText: PropTypes.string,
    })
  ).isRequired,
};

export default Options3;
